import React, { useEffect, useState, useRef } from 'react'
import { PageProps, Link } from 'gatsby'
import { Head } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Home.module.styl'
import logoUnicred from '~/assets/svg/svg-logo.svg'
import logoJuntos from '~/assets/svg/juntos-transformamos.png'
import Departments from './Departments'
import PrincipaisEntregaveis from './PrincipaisEntregaveis'
import EntregaveisDetalhe from './EntregaveisDetalhe'
import iconesRight from '../assets/img/img-icones.png'

const Home = (props: PageProps & { '*'?: string }) => {
  const { location, pageContext, '*': slug } = props

  if (slug) {
    const slashCount = slug.split('/').length
    if (slashCount === 1) {
      return <Departments {...props} />
    } else if (slashCount === 2) {
      const lastSlug = slug.split('/')[1]
      if (lastSlug === 'principais-entregaveis') {
        return (
          <PrincipaisEntregaveis {...{ ...props, '*': slug.split('/')[0] }} />
        )
      } else {
        return <EntregaveisDetalhe {...props} />
      }
    }
  }

  const context = useApi(pageContext, 'page-home')
  const { banners, departments } = context

  const [index, setIndex] = useState(0)
  const timeOutRef = useRef(null)

  const resetTimeOut = () => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current)
    }
  }

  useEffect(() => {
    resetTimeOut()
    timeOutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === banners.length - 1 ? 0 : prevIndex + 1
        ),
      5000
    )
    return () => {
      resetTimeOut()
    }
  }, [index])

  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <nav>
          <ul>
            {departments.map(({ name, slug }, index) => (
              <li key={index}>
                <Link to={`/${slug}/`}>{name}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <img src={logoUnicred} alt="Logo da Unicred" />
        <section>
          <h1>Plano de cargos, carreira e remuneração</h1>
          <p>
            Aqui no Sistema Unicred Conexão, utilizamos a estrutura de cargos
            como norteador para processos de atração e desenvolvimento de
            profissionais. Ela é a primeira etapa a ser construída na elaboração
            de um plano de cargos e salários.
          </p>
        </section>
        <div>
          <img
            src={iconesRight}
            alt="Imagem do lado direito"
            className={st.teste}
          />
        </div>
        {/* <div id="mask2">
          {banners.map(({ image, title }, idx) => (
            <img
              src={image}
              alt={title}
              key={idx}
              className={index === idx && st.active}
            />
          ))}
        </div>
        <div>
          <svg>
            <clipPath id="svgMask" clipPathUnits="objectBoundingBox">
              <path d="M0,0.281 A0.075,0.058,0,0,1,0.075,0.223 H0.356 C0.391,0.223,0.416,0.195,0.403,0.17 C0.391,0.146,0.38,0.118,0.379,0.092 C0.377,0.022,0.473,-0.001,0.519,0 C0.565,0.001,0.654,0.016,0.658,0.096 C0.66,0.118,0.649,0.145,0.636,0.169 C0.622,0.195,0.646,0.223,0.682,0.223 H0.925 A0.075,0.058,0,0,1,1,0.281 V0.941 A0.075,0.058,0,0,1,0.925,1 H0.682 S0.576,1,0.53,1 C0.516,0.999,0.542,1,0.533,1 C0.512,1,0.467,1,0.458,1 H0.075 A0.075,0.058,0,0,1,0,0.941" />
            </clipPath>
          </svg>
        </div>
        <img
          src={logoJuntos}
          className={st.contentMobile}
          alt="Logo Juntos Transformamos"
        /> */}
        <a
          href="https://www.tiki.com.br/"
          title="Site da Tiki Web"
          target="_blank"
          rel="noreferrer"
        >
          Tiki
        </a>
      </main>
    </>
  )
}

export default Home
